<template>
  <div class="notice_body-list" :class="{ 'pb-3': !hasMore }">
    <table>
      <caption>
        공지사항 목록
      </caption>
      <colgroup>
        <col width="8%" />
        <col width="*" />
        <col width="10%" />
        <col width="10%" />
        <col width="10%" />
        <!-- <col width="10%"> -->
      </colgroup>
      <thead>
      <tr>
        <th scope="col">번호</th>
        <th scope="col">제목</th>
        <th scope="col">작성자</th>
        <th scope="col">첨부</th>
        <th scope="col">등록일</th>
      </tr>
      </thead>
      <tbody v-for="(item, idx) in items" :key="idx">
      <tr>
        <td  style="pointer-events: none" v-if="item.upporFixYn === 'Y'" class="icon"><img src="../../assets/img/notice/ico_notice.png" alt="공지사항 아이콘" /></td>
        <td v-else class="icon" style="pointer-events: none">{{ item.totalCount - (item.pageNo - 1) * 10 - idx }}</td>

        <td class="text-left ps-3">
          <router-link :to="item.viewLink" class="image-link" data-anim="true">
            {{ item.ntcTit }}
          </router-link>
        </td>
        <td class="layout_mo">
          <div class="title_mo">
            <router-link :to="item.viewLink" class="image-link" data-anim="true">
              <div>
                <img v-if="item.upporFixYn === 'Y'" src="../../assets/img/notice/ico_notice.png" alt="공지사항 아이콘" style="max-width: 20px; margin-top: 1px" />
                <span class="text" style="vertical-align: 4px"> {{ item.ntcTit }} </span>
              </div>
            </router-link>
          </div>
          <div class="info_mo">
            <ul>
              <li>
                <span>작성자 :</span>
                <span>관리자</span>
              </li>
              <li>
                <span >등록일</span>
                <span>{{ item.regDt.getFullYear() + "-" + ("00"+(item.regDt.getMonth()+1).toString()).slice(-2) + "-" + ("00"+item.regDt.getDate().toString()).slice(-2) }}</span>
              </li>
            </ul>
          </div>
        </td>
        <td style="pointer-events: none">관리자</td>
        <td v-if="item.atchFilePtUrl" class="attach">
          <a href="javascript:">
            <button style="height: 50.5px" v-if="item.atchFilePtUrl" class="btn" :disabled="downing" @click="clickDownload(item.ntcId, item.atchFileNm)">
                <span class="text">
                  <img v-if="item.fileExt !== '' || item.fileExt === 'xlx'" :src="getIconPath(item.fileExt)" />
                  <img v-else src="../../assets/img/notice/ico_etc.png" />
                </span>
            </button>
          </a>
        </td>
        <td v-else class="attach" style="pointer-events: none">
          <a href="javascript:"></a>
        </td>
        <td style="pointer-events: none">{{ item.regDt.getFullYear() + "-" +  ("00"+(item.regDt.getMonth()+1).toString()).slice(-2)  + "-" + ("00"+item.regDt.getDate().toString()).slice(-2) }}</td>
        <!-- <td>{{ item.inqCnt }}</td> -->
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { ACT_DOWN_ATTACH_NOTICE2 } from "../../store/_act_consts";
export default {
  name: "NoticeCard",
  props: {
    hasMore: {
      type: Boolean,
      default: false,
    },
    items: Array,
    isTag: Boolean,
    isBadge: Boolean,
  },
  data: () => ({
    ntcId: 0,
    atchFileNm: "",
    atchFilePtUrl: "",
    downing: false,
  }),
  created() {
    this.ntcId = this.$route.params.ntcId;
  },
  methods: {
    getIconPath(fileExt) {
      try {
        return require(`../../assets/img/notice/ico_${fileExt}.png`);
      } catch (error) {
        return require(`../../assets/img/notice/ico_etc.png`);
      }
    },
    clickDownload(ntc_id, atch_File_Nm) {
      this.downing = true;
      this.$store.dispatch(`notice2/${ACT_DOWN_ATTACH_NOTICE2}`, {
        ntcId: ntc_id,
        fileName: atch_File_Nm,
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", atch_File_Nm);
        document.body.appendChild(link);
        link.click();
        this.downing = false;
      })
      .catch((err) => {
        console.log(err);
        this.downing = false;
      });
    },
  },
};
</script>
